import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>COMPAREZ NOTRE GAMME DE PRODUITS</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produits/',
    label: 'VOIR TOUS LES PRODUITS',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Avec un chargement lourd</li>\n<li><sup>**</sup>vs. Finish Power basé sur un poids moyen</li>\n<li><sup>***</sup>S’attaque même aux taches de brûlé</li>\n<li><sup>****</sup>Ingrédient : Enzyme</li>\n</ul>',
      rows: [
        {
          text: 'Propreté',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt95325f54ff3b2691/677d498721f2a7d51f507268/clean.svg',
        },
        {
          text: 'Brillance',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltafa7ad17332ebfe6/677d497d316bc689c570b59f/shine.svg',
        },
        {
          text: 'Taches incrustées depuis 48h<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt427f1ac30520382a/677d497dbc3df478bb01825a/dried-on.svg',
        },
        {
          text: 'Taches de graisse',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt950c924190ffcdf1/677d497d4275ab45c9f74fd3/tough-on-grease.svg',
        },
        {
          text: 'Stop au pré-lavage',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt75f4cc52530b924c/677d497d6d31b7d49ac18b33/skip-the-rinse.svg',
        },
        {
          text: 'Capsules sans emballage individuel',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfad731c0423da783/677d497dbc3df44d9e01825e/wrapper-free-tabs.svg',
        },
        {
          text: 'Dissolution rapide',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8c48fa6e258c5b4a/677d497d43d191013e612a9d/fast-dissolving.svg',
        },
        {
          text: 'Avec moins d’ingrédients chimiques<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt56d51174c45a4028/677d497d3bc87f276af2d957/less-chemical-weight.svg',
        },
        {
          text: 'Protection du verre',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt860880330d57f440/677d497dc9370ed91c228f4c/glass-protection.svg',
        },
        {
          text: 'Boost de fraîcheur',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltf6f70d0b55bebdf7/677d497dae5938983f3efced/freshness-boost.svg',
        },
        {
          text: 'Taches de brûlé<sup>***</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltabc5366ae9d06172/677d498743d1919c1c612aa1/burnt-on-stains.svg',
        },
        {
          text: 'Libération séquentielle des ingrédients<sup>****</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc3b32ea6323d7bf6/677d497d21f2a7c550507264/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8c90908f9a077b97/677d4ac305945faedbe079d0/ultimate-plus-diff-group.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produits/detergents/ultimate-plus/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfbed24bef3f4af03/677d4ac3b6795546a087a6ef/ultimate-diff-group.png',
          productName: 'ULTIMATE',
          productLink: '/produits/detergents/ultimate/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2726ee1573cfee4e/677d4ac3c9370e310b228f54/quantum-diff-group.png',
          productName: 'QUANTUM',
          productLink: '/produits/detergents/quantum/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt2b8f37f18a6fd0c5/677d4ac3827b1f1ba4ab1e16/power-plus-diff-group.png',
          productName: 'POWER',
          productLink: '/produits/detergents/tablettes-tout-en-1-max/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt487bcdbb5adff662/677d4958d05c39108c47732b/ultimate.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produits/detergents/ultimate/',
        text: 'DÉCOUVREZ ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt240271bd6e06aeea/677d4958d05c395685477329/tough-on-grease_(1).webp',
          name: 'EFFICACE SUR LES TACHES DE GRAISSE',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8d6ec9d6c6f17c7a/677d495821f2a7101750725f/dried-on-stains.webp',
          name: 'ACHES INCRUSTÉES DEPUIS <strong>48</strong>H<sup>i</sup>',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8fbcce6faeaf9ee3/677d4958c9370eaa68228f48/ultimate-plus.webp',
      cardStyle: 'black',
      button: {
        link: '/produits/detergents/ultimate-plus/',
        text: 'DÉCOUVREZ NOTRE MEILLEUR PRODUIT, ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt740edc1f8ca4a991/677d4958981acb8ea0d2e5df/extra-cleaning-power.webp',
          name: '<strong>3X</strong> PLUS D’ACTIFS NETTOYANTS<sup>ii</sup>',
          styleType: 'text-on-image',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt99066892f4cfafa6/677d495837fb04379cab90d4/tough-on-grease-gold.webp',
          name: 'EFFICACE SUR LES TACHES DE GRAISSE',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb648038158f93af4/677d4958d05c395b81477323/burnt-on-stains.webp',
          name: 'TACHES DE BRÛLÉ<sup>iii</sup>',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte3131f28cd7b5f4f/677d49584275ab88def74fce/quantum.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produits/detergents/quantum/',
        text: 'DÉCOUVREZ QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt240271bd6e06aeea/677d4958d05c395685477329/tough-on-grease_(1).webp',
          name: 'EFFICACE SUR LES TACHES DE GRAISSE',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: '15% DE REDUCTION SUR NOTRE GAMME ULTIMATE PLUS',
  description:
    'Inscrivez-vous et profitez d’une réduction sur notre meilleure gamme de capsules FINISH ULTIMATE PLUS.',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc720e55267990134/67a475e02109c14f91a42b79/ultimate-plus-73-regular.webp',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc720e55267990134/67a475e02109c14f91a42b79/ultimate-plus-73-regular.webp',
    alt: 'finish',
  },
  thankYou: {
    headline: 'Vous pouvez dès maintenant utiliser votre code de réduction sur Amazon',
    description: 'Votre code: FINISH15',
    closeButton: {
      label: 'Fermer',
      redirectUrl: '/',
    },
  },
  form: {
    id: 'e33539ab-0824-4a9f-a99c-4450f26fe47d',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'veuillez remplir le champ',
  },
};
